<template>
    <div>
        <div v-if="activityLoading" class="loading__spinner">
            <span><b-spinner class="mr-1" small></b-spinner>Loading...</span>
        </div>
        <ul v-if="hasActivity" class="activity-list action-logs-list">
            <li v-for="(activity, index) in activities" :key="index">
                <div class="activity-list__author">
                        <Avatar v-if="activity.user_name === 'SwitchPitch'"
                                background-color="#043648"
                                color="#00AFEF"
                                initials="SP"
                                :size="42"
                        ></Avatar>
                        <Avatar v-else
                                :username="activity.user_name"
                                :src="activity.user_avatar"
                                :size="42"
                        ></Avatar>
                    <div>
                        <h5 class="heading5 heading5--secondary" :class="{'deleted': activity.user_deleted}">
                            <template v-if="isPreviewList">
                                {{ stringHelper.getShortFullName(activity.user_name) }}
                            </template>
                            <template v-else>
                                {{ activity.user_name }}
                            </template>
                            <template v-if="activity.user_deleted">(D)</template>
                        </h5>
                        <time class="activity-list__time">{{dateHelper.decodeDate(activity.date)}}</time>
                    </div>
                </div>

                <div class="activity-list__content">
                    <div class="activity-list__content__inner">
                        <h5 class="activity-list__title" :class="getActivityIconClass(activity)">{{ activity.title }}
                            <template v-if="activity.interaction_date"> <span style="font-weight: normal;" class="ml-2">(Interaction Date: {{dateHelper.decodeDate(activity.interaction_date)}})</span></template>
                            <template v-if="typeof activity.custom_value !== 'undefined'">:<span style="font-weight: normal;" class="ml-2">{{activity.custom_value.field}}</span></template>
                        </h5>

                        <template v-if="!isPreviewList">
                            <span v-for="tag in activity.tags" :key="tag" class="activity-list__content__tag">{{tag}}</span>
                        </template>
                    </div>

                    <template v-if="!activity.text.includes('<span') && activity.object_type !='news' && activity.type !='update_location'"> <!-- Only use v-clamp for case if note doesn't contain mention, otherwise we should use v-html statement -->
                        <v-clamp autoresize :max-lines="2" style="white-space: pre-wrap;">
                            {{activity.text}}
                            <template #after="{ toggle, expanded, clamped }">
                                <a v-if="expanded" class="link-normal" @click="toggle"> less</a>
                                <a v-if="clamped" class="link-normal" @click="toggle"> more</a>
                            </template>
                        </v-clamp>
                    </template>
                    <template v-else>
                        <div class="activity-list__text" v-html="activity.text"></div>
                    </template>

                    <template v-if="typeof activity.tag !== 'undefined'">
                        <div class="tags-list tags-list--light">
                            <div class="tags-list__tag">
                                <template v-if="typeof activity.tag === 'object'">
                                    <a :id="'tag-' + index + '-' + activity.tag.id" :href="'/startups?tag=' + activity.tag.name" target="_blank">{{activity.tag.name}}</a>
                                    <b-tooltip :target="'tag-' + index + '-' + activity.tag.id" placement="right" variant="light" :title="activity.tag.description"/>
                                </template>

                                <template v-else>
                                    <a :href="'/startups?tag=' + activity.tag" target="_blank">{{activity.tag}}</a>
                                </template>
                            </div>
                        </div>
                    </template>

                    <template v-if="typeof activity.tracker !== 'undefined'">
                        <div>
                            <a class="action-logs-list__item" :href="'/trackers/' + activity.tracker.id"
                               target="_blank">{{activity.tracker.name}}</a>
                        </div>
                    </template>

                    <template v-if="typeof activity.contact !== 'undefined'">
                        <div class="action-logs-list__user-inner">
                            {{activity.contact}}
                        </div>
                    </template>

                    <template v-if="typeof activity.task !== 'undefined'">
                        <div class="action-logs-list__task">
                            <a :href="'/?open_task=' + activity.task.id" target="_blank">{{activity.task.name}}</a>
                        </div>
                    </template>

                    <template v-if="typeof activity.custom_value !== 'undefined'">
                        <div class="action-logs-list__custom">
                            <template v-if="activity.custom_value.value.length">
                                <v-clamp autoresize :max-lines="3" v-if="activity.clamped"> {{activity.custom_value.value}}
                                    <template #after="{ clamped }">
                                        <a v-if="clamped" class="link-normal" @click="activities[index].clamped = false"> more</a>
                                    </template>
                                </v-clamp>

                                <template v-else>
                                    <div v-html="activity.custom_value.raw_value"></div>
                                    <a v-if="!activity.clamped && activity.bigText" class="link-normal" @click="clampText(index, true)"> less</a>
                                </template>
                            </template>
                        </div>
                    </template>

                    <template v-if="typeof activity.file !== 'undefined'">
                        <div class="action-logs-list__file-wrapper">
                            <div class="action-logs-list__file">
                                {{ activity.file }}
                            </div>
                        </div>
                    </template>
                </div>

                <template v-if="activity.object_type === 'news'">
                    <b-dropdown v-if="activity.can_edit" right
                                class="dropdown-single dropdown-single--big ml-auto btn-group icons-list pl-5">
                        <b-dropdown-item class="icons-list__edit" @click="editArticle(activity.object_id)">
                            Edit Article
                        </b-dropdown-item>
                        <b-dropdown-item class="icons-list__delete2" @click="removeArticle(activity.object_id)">
                            Delete Article
                        </b-dropdown-item>
                    </b-dropdown>
                </template>

                <template v-if="['startup-note','interaction'].includes(activity.object_type) && (activity.can_edit || $store.state.user.is_admin || (activity.type === 'note' && activity.user_id === $store.state.user.id))">
                    <b-dropdown right
                                class="dropdown-single dropdown-single--big ml-auto btn-group drag-n-drop icons-list pl-4">
                        <b-dropdown-item class="icons-list__edit" v-if="activity.can_edit"
                                         @click="editActivity(activity)">
                            Edit
                        </b-dropdown-item>
                        <b-dropdown-item class="icons-list__delete2" v-if="activity.can_delete"
                                         @click="removeActivity(activity.log_id)">
                            Remove
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </li>
        </ul>
        <div v-if="hasActivity === false" class="card card--empty card--transparent">
            <header class="card__header">
                <h4 class="heading4">No Activity</h4>
            </header>

            <p>Add notes, interactions or news items to keep track of your knowledge and relationship with this
                startup.</p>
        </div>

        <edit-note
            ref="editNote"
            company-type="startup"
            :company-id="companyId"
            :usersMentionList="usersMentionList"
            :isPreviewList="isPreviewList"
            @saved="activityChanged"
        >
        </edit-note>

        <edit-interaction
            ref="editInteraction"
            company-type="startup"
            :usersMentionList="usersMentionList"
            :isPreviewList="isPreviewList"
            @saved="activityChanged"
        >
        </edit-interaction>

        <edit-article
            ref="editArticle"
            :startup-id="companyId"
            :isPreviewList="isPreviewList"
            @saved="activityChanged"
        >
        </edit-article>
    </div>
</template>

<script>
    import articles from "@/services/articles.service";
    import actionLogs from "@/services/action-logs.service";

    export default {
        name: 'ActivityList',
        components: {
            VClamp: () => import("vue-clamp"),
            Avatar: () => import("vue-avatar"),
            EditArticle: () => import("@/components/articles/EditArticleModal"),
            EditNote: () => import("@/components/notes/EditNoteModal"),
            EditInteraction: () => import("@/components/interactions/EditInteractionModal"),
        },
        props: {
            activities: {type: Array, required: true},
            companyId: {type: Number, required: true},
            usersMentionList: {type: Array, required: true},
            activityLoading: {type: Boolean, default: false},
            hasActivity: {type: Boolean},
            isPreviewList: {type: Boolean},
        },
        methods: {
            getActivityIconClass(activity) {
                if (activity.type === 'note') {
                    return 'activity-list__title--note'
                } else if (activity.type === 'phone_call') {
                    return 'activity-list__title--call'
                } else if (activity.type === 'email') {
                    return 'activity-list__title--email'
                } else if (activity.type === 'meeting') {
                    return 'activity-list__title--meeting'
                } else {
                    return 'activity-list__title--' + activity.type
                }
            },
            activityChanged() {
                this.$emit('activityChanged')
            },
            editActivity(activity) {
                if (activity.object_type === 'startup-note') {
                    this.$refs.editNote.open(activity.object_id)
                }

                else if (activity.object_type === 'interaction') {
                    this.$refs.editInteraction.open(activity.object_id)
                }
            },
            removeActivity(logId) {
                if (confirm("Remove activity?")) {
                    actionLogs.removeActivity(logId).then(() => {
                        this.activityChanged()
                    })
                }
            },
            editArticle(id) {
                this.$refs.editArticle.open(id)
            },
            removeArticle(id) {
                if (confirm("Remove article?")) {
                    articles.delete(id).then(() => {
                        this.activityChanged()
                    })
                }
            },

            clampText(index, value) {
                this.$emit('clamp-text', index, value);
            }
        }
    }
</script>

<style scoped>

</style>
